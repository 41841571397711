import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Button } from '../button';

import * as businessStyles from './index.module.css';

export const BusinessType = ({ answer, onClick, isSelected, variant }) => (
	<Button
		variant={variant}
		onClick={onClick}
		data-testid={answer.answer}
		className={clsx(
			'!flex',
			'w-full',
			'flex-col',
			'h-full',
			businessStyles.businessType
		)}
		style={{ '--space-vertical': 'var(--space-s)' }}
	>
		<header
			className={clsx(
				'flex',
				'items-center',
				'justify-between',
				'w-full'
			)}
		>
			<h3
				className={clsx(
					'text-lg',
					'font-bold',
					isSelected ? 'text-blue-400' : ''
				)}
			>
				{answer.answer}
			</h3>
			<span
				className={clsx(
					'px-3',
					'py-1',
					'border-blue-300',
					'rounded-md',
					'border-1',
					isSelected ? 'bg-blue-400 border-blue-400 text-white' : ''
				)}
			>
				{isSelected ? 'Selected' : 'Select'}
			</span>
		</header>
		{answer.subSectors.length > 0 ? (
			<>
				<hr className="w-full border-blue-200" />
				<p className="w-full font-centra-medium">Such as:</p>
				<ul className="flex flex-wrap w-full mb-4 gap-y-1 gap-x-4 font-centra-book">
					{answer.subSectors.map((subSector) => (
						<li
							key={`answer-sub-sector-${subSector.id}`}
							className={businessStyles.businessTypeSubSector}
						>
							{subSector.name}
						</li>
					))}
				</ul>
			</>
		) : null}

		{answer.subSectors.length === 0 && isSelected ? (
			<hr className="w-full border-0" />
		) : null}

		{isSelected && answer.worksWith ? (
			<p
				className={clsx(
					'p-2',
					'-mx-6',
					'-mb-5',
					'mt-auto',
					'overflow-hidden',
					'text-center',
					'bg-brand-pale-500',
					'font-centra-medium',
					'text-sm',
					businessStyles.businessCount
				)}
			>
				We already work with {answer.worksWith} businesses in your
				sector
			</p>
		) : null}
	</Button>
);

BusinessType.defaultProps = {
	isSelected: false,
	variant: '',
};

BusinessType.propTypes = {
	answer: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		question: PropTypes.string,
		answer: PropTypes.string,
		resultText: PropTypes.string,
		subSectors: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				name: PropTypes.string,
			})
		),
		worksWith: PropTypes.number,
	}).isRequired,
	onClick: PropTypes.func.isRequired,
	isSelected: PropTypes.bool,
	variant: PropTypes.string,
};
