import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { navigate } from 'gatsby';
import { questionType, answersType } from '../../../types';

import {
	countQuestions,
	countAnswered,
	deleteNextAnswers,
	checkInvalid,
	getAnswersScore,
	deleteSubQuestions,
} from '../../../utils';

import { Header } from '../../molecules/header';
import { Question } from '../../molecules/question';
import { Button } from '../../atoms/button';
import { CloseButton } from '../../atoms/close-button';

import LeftArrowIcon from '../../../assets/left-arrow.svg';
import RestartIcon from '../../../assets/restart.svg';

const Questions = ({
	allData,
	pageData,
	answers,
	setAnswers,
	setPageData,
	pageIndex,
	setPageIndex,
	isUrgent,
	setUrgent,
	setIsComplete,
}) => {
	const questionData = allData;

	const [answered, setAnswered] = useState(0);
	const [required, setRequired] = useState(pageData.questions.length);

	// These are based answers given at different stages
	const [subjects, setSubjects] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [subAreas, setSubAreas] = useState([]);
	const [subQuestions, setSubQuestions] = useState([]);
	const [joinedQuestions, setJoinedQuestions] = useState([]);

	const [currentQuestion, setCurrentQuestion] = useState(null);

	const [isInvalid, setIsInvalid] = useState({});
	const [isDirty, setIsDirty] = useState(false);

	const prevPageRef = useRef(pageIndex);
	const invalid = required !== answered;

	const handleBackTracking = () => {
		const hasGtag = typeof window.gtag === 'function';

		if (!hasGtag) return;

		window.gtag('event', 'click', {
			click_text: 'Back',
			click_type: 'button',
		});
	};

	const handleAnswersTracking = () => {
		const currentAnswers = answers[pageIndex];
		const hasGtag = typeof window.gtag === 'function';
		const isSubQuestion =
			currentAnswers && currentAnswers.type === 'subQuestion';

		if (!hasGtag || !currentAnswers || isSubQuestion) return;

		window.gtag('event', 'question_answered', {
			question: currentAnswers.question,
			answers: currentAnswers.answers.join(', '),
			total_answers: currentAnswers.answers.length,
			question_type: 'primary',
		});
	};

	// TODO - this is for debug to track questions that are answered
	/* useEffect(() => {
		// console.log(JSON.stringify(answers, null, 2));
		console.log('Answers', answers);
	}, [answers]); */

	useEffect(() => {
		const hasWindow = typeof window !== 'undefined';
		const hasGtag = typeof window.gtag === 'function';
		const isCurrent = pageIndex === prevPageRef.current;

		if (!hasWindow || !hasGtag || !isCurrent) return;

		const stepNumber = pageIndex === 5 ? pageIndex : pageIndex + 1;

		window.gtag('event', 'page_view', {
			page_location: `/advice/step-${stepNumber}`,
			page_title: `Business Health Assistant - Step ${stepNumber}`,
		});
	}, [pageData, pageIndex]);

	useEffect(() => {
		// We've got to the next page to set any extra data we have
		if (prevPageRef.current < pageIndex) {
			if (subjects.length > 0) {
				// Make this array unique
				const subSs = [...new Set(subjects)];
				questionData.pages[pageIndex].questions[0].answers =
					questionData.subjects.filter(
						(subject) => subSs.indexOf(subject.id) !== -1
					);
			}

			if (subCategories.length > 0) {
				const subCs = [...new Set(subCategories)];
				questionData.pages[pageIndex].questions[0].answers =
					questionData.subCategories.filter(
						(subCategory) => subCs.indexOf(subCategory.id) !== -1
					);
			}

			if (subAreas.length > 0) {
				const subAs = [...new Set(subAreas)];
				questionData.pages[pageIndex].questions[0].answers =
					questionData.subAreas.filter(
						(subArea) => subAs.indexOf(subArea.id) !== -1
					);
			} else if (pageIndex === 4) {
				// If there are no sub areas we can skip to the sub questions
				setPageIndex((current) => current + 1);
				return;
			}

			// Here we need to check if we're on the sub areas section and move on if there are none
			if (!subAreas.length && subQuestions.length > 0) {
				const subQs = [...new Set(subQuestions)];
				questionData.pages[pageIndex].questions =
					questionData.subQuestions.filter(
						(subQuestion) => subQs.indexOf(subQuestion.id) !== -1
					);
			}
		} else if (pageIndex === 4 && !subAreas.length) {
			// If there are no sub areas we can skip to the sub questions
			setPageIndex((current) => current - 1);
			return;
		}

		const numQuestions = countQuestions(
			questionData.pages[pageIndex].questions
		);
		setPageData(questionData.pages[pageIndex]);
		setRequired(numQuestions);

		// Sets how many questions we've answered
		const pageQuestions = questionData.pages[pageIndex].questions;
		const isAnswered = countAnswered(pageQuestions, answers);
		setAnswered(isAnswered);

		setSubjects([]);
		setSubCategories([]);
		// We need to keep any sub questions through two stages
		if (pageIndex < 4) {
			setSubAreas([]);
			setSubQuestions([]);
		}
		prevPageRef.current = pageIndex;

		setIsDirty(false);
	}, [pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (currentQuestion) {
			if (!joinedQuestions.length) {
				// Remove all of the sub questions for this question recursively
				if (
					currentQuestion?.subQuestions &&
					currentQuestion?.subQuestions.length > 0
				) {
					deleteSubQuestions(currentQuestion);
				}
			} else {
				currentQuestion.subQuestions = questionData.subQuestions.filter(
					(joinedQuestion) =>
						joinedQuestions.indexOf(joinedQuestion.id) !== -1
				);
			}

			// Remove the following answers for this question if there are no subquestions
			// but there were and they've been answered, or we've changed page back and then
			// changed an answer
			if (
				currentQuestion.subQuestions &&
				currentQuestion.subQuestions.length === 0 &&
				pageIndex > 0 &&
				pageIndex < 4
			) {
				deleteNextAnswers({ answers, currentQuestion, setAnswers });
			}

			// This part is used to update the current number of required questions
			// based on the ones that are currently showing (including sub questions)
			const intQuests = countQuestions(
				questionData.pages[pageIndex].questions
			);
			setRequired(intQuests);

			// Set the data back to the page
			setPageData(questionData.pages[pageIndex]);
		}
	}, [joinedQuestions]); // eslint-disable-line react-hooks/exhaustive-deps

	// Used to update the current number of answered questions when the current question changes
	useEffect(() => {
		if (currentQuestion) {
			if (pageIndex > 0 && pageIndex < 4) {
				// Remove the last answer from the answers object as they have changed
				deleteNextAnswers({ answers, currentQuestion, setAnswers });
			}

			// If the following page has any sub questions on it we need to remove the
			// sub questions as the answers have changed
			if (questionData.pages[pageIndex + 1]) {
				questionData.pages[pageIndex + 1].questions.forEach((aQuest) =>
					deleteSubQuestions(aQuest)
				);
			}

			// Check if any answers add up to an urgent score (> 1)
			if (pageIndex >= 4) {
				if (getAnswersScore(answers) >= 1) {
					setUrgent((current) => (current === 1 ? current : 2));
				} else {
					setUrgent(0);
				}
			}

			// Check for this question being valid
			checkInvalid({
				questions: [currentQuestion],
				answers,
				setIsInvalid,
				ignoreSub: true,
			});
		}

		// Sets how many questions we've answered
		const pageQuestions = questionData.pages[pageIndex].questions;
		const numAnswered = countAnswered(pageQuestions, answers);
		setAnswered(numAnswered);
	}, [currentQuestion, answers]); // eslint-disable-line react-hooks/exhaustive-deps

	const isComplete =
		!invalid &&
		(isUrgent === 0 || pageIndex < 4) &&
		typeof questionData.pages[pageIndex + 1] === 'undefined';

	return (
		<>
			{pageIndex > 0 ? (
				<Header
					restartBtn={
						<CloseButton
							className="!py-xxs-f !px-l-f"
							destination="/"
							promptTitle="Restart Triage"
							promptDesc="Are you sure you want to restart? You will lose all information added so far."
							id="questionsRestart"
						>
							<RestartIcon className="mr-2" />
							Restart
						</CloseButton>
					}
				/>
			) : null}
			<ol
				className={clsx(
					'wrapper-small',
					pageIndex === 1 ? 'pb-10 md:pb-14' : ''
				)}
			>
				{pageData.questions.map((question) => (
					<li key={`main-question-${question.id}`}>
						<Question
							question={question}
							pageIndex={pageIndex}
							isInvalid={isInvalid}
							setCurrentQuestion={setCurrentQuestion}
							setAnswered={setAnswered}
							answers={answers}
							setAnswers={setAnswers}
							setSubjects={setSubjects}
							setSubCategories={setSubCategories}
							setSubAreas={setSubAreas}
							setSubQuestions={setSubQuestions}
							setJoinedQuestions={setJoinedQuestions}
							setUrgent={setUrgent}
						/>
					</li>
				))}
			</ol>
			<div
				className={clsx(
					'text-center',
					pageIndex === 1
						? 'fixed bottom-0 left-0 w-full py-3 bg-white shadow-sticky-bar flex flex-wrap justify-center items-center'
						: ''
				)}
			>
				<Button
					variant="White"
					onClick={() => {
						handleBackTracking();

						if (pageIndex === 0) {
							return navigate('/');
						}

						setPageIndex((current) => current - 1);
					}}
					className="flex items-center mr-2 !py-xxs-f !px-l-f"
				>
					<LeftArrowIcon className="mr-2 max-w-none" />
					Back
				</Button>

				<Button
					variant="Main"
					className="!px-8"
					onClick={() => {
						handleAnswersTracking();
						setIsDirty(true);
						checkInvalid({
							questions: pageData.questions,
							answers,
							setIsInvalid,
							ignoreSub: false,
						});
						if (!invalid && (isUrgent === 0 || pageIndex < 4)) {
							// That was the last set of questions!
							if (
								typeof questionData.pages[pageIndex + 1] ===
								'undefined'
							) {
								setIsComplete(true);
							} else {
								setPageIndex((prevIndex) => prevIndex + 1);
								setAnswered(0);
							}
						}
						// Show the urgent message thing if you try to go next and everything
						// is valid but you have an urgent message
						if (isUrgent === 1 && pageIndex >= 4) {
							setUrgent(2);
						}
					}}
				>
					{isComplete ? 'View Results' : 'Next'}
				</Button>
				<p
					className="block w-full mt-2 invalid-message text-alert-500"
					aria-live="assertive"
				>
					{isDirty && invalid ? 'Please fill in all questions' : ''}
				</p>
			</div>
		</>
	);
};

Questions.propTypes = {
	allData: PropTypes.shape({
		question: PropTypes.string,
		answers: PropTypes.arrayOf(PropTypes.number.isRequired),
	}).isRequired,
	pageData: PropTypes.shape({
		questions: PropTypes.arrayOf(questionType),
	}).isRequired,
	answers: answersType.isRequired,
	setAnswers: PropTypes.func.isRequired,
	pageIndex: PropTypes.number.isRequired,
	setPageIndex: PropTypes.func.isRequired,
	setPageData: PropTypes.func.isRequired,
	isUrgent: PropTypes.number.isRequired,
	setUrgent: PropTypes.func.isRequired,
	setIsComplete: PropTypes.func.isRequired,
};

export { Questions };
