import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { questionType, answersType } from '../../../types';

import {
	findAnswer,
	findAllSubAnswers,
	getNewAnswers,
	getQuestionAnswers,
} from '../../../utils';

import { BusinessType } from '../../atoms/business-type';
import { Button } from '../../atoms/button';

const Question = ({
	question,
	pageIndex,
	originalQuestion,
	isInvalid,
	setCurrentQuestion,
	setAnswered,
	answers,
	setAnswers,
	setSubjects,
	setSubCategories,
	setSubAreas,
	setSubQuestions,
	setJoinedQuestions,
	setUrgent,
	isSubQuestion,
}) => {
	const handleSubQuestionAnswersTracking = (answer) => {
		const hasGtag = typeof window.gtag === 'function';

		if (!hasGtag) return;

		window.gtag('event', 'question_answered', {
			question: question.question,
			answers: answer.answer,
			total_answers: 1,
			question_type: question.type,
		});
	};

	// This uses the changed answers to work out categories, sub areas or sub questions
	// based on the latest questions answered
	useEffect(() => {
		const questionAnswers = getQuestionAnswers(answers, question.id);
		if (questionAnswers) {
			const allAnswers = question.answers.filter((anAnswer) =>
				questionAnswers.answers.includes(anAnswer.answer)
			);

			// Asd answers change these to reflect the given answers as they
			// are updated
			const subCats = allAnswers.flatMap((theAnswer) =>
				theAnswer.subCategories && theAnswer.subCategories.length > 0
					? theAnswer.subCategories
					: []
			);
			const subAs = allAnswers.flatMap((theAnswer) =>
				theAnswer.subAreas && theAnswer.subAreas.length > 0
					? [...theAnswer.subAreas]
					: []
			);
			const subQs = allAnswers.flatMap((theAnswer) =>
				theAnswer.subQuestions && theAnswer.subQuestions.length > 0
					? [...theAnswer.subQuestions]
					: []
			);

			if (subCats.length > 0) {
				setSubCategories(subCats);
			}

			setSubAreas(subAs.length > 0 ? subAs : []);

			// If there are sub questions of sub areas we need to oin
			// them, otherwise just set them
			if (subQs.length > 0) {
				setSubQuestions((current) =>
					pageIndex === 3 ? subQs : [...current, ...subQs]
				);
			}
		}
	}, [answers]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const hasWIndow = typeof window !== 'undefined';
		const hasGtag = typeof window.gtag === 'function';

		if (!hasWIndow || !hasGtag) return;

		window.gtag('event', 'question_viewed', {
			question: question.question,
			answers: question.answers.map((answer) => answer.answer).join(', '),
			total_answers: question.answers.length,
		});
	}, [question]);

	// Check to see if the question exists in the invalid object
	const questionInvalid =
		typeof isInvalid[String(question.id)] !== 'undefined' &&
		isInvalid[String(question.id)];

	const questionClasses = clsx(
		'relative before:absolute before:top-0 before:-left-4 before:w-1 before:h-full before:transition-colors before:duration-200 before:motion-reduce:transition-none',
		questionInvalid ? 'before:bg-alert-400' : '',
		'mt-6',
		!isSubQuestion ? 'question-test' : ''
	);

	// TODO - will need to make the question index flow better somehow
	return (
		<div
			className={clsx(questionClasses, 'flow-vertical')}
			style={{ '--space-vertical': 'var(--space-m)' }}
		>
			<hgroup>
				<h2 className="text-xl font-centra-medium">
					{question.question}
				</h2>

				{question.description ? (
					<p className="text-sm lg:text-lg">{question.description}</p>
				) : null}

				{questionInvalid ? (
					<p
						className="mt-1 text-alert-500 font-centra-book question-invalid"
						role="alert"
					>
						This question is required
					</p>
				) : null}
			</hgroup>
			{question.answers.length > 0 ? (
				<>
					<ul
						className={clsx(
							pageIndex === 1 ? 'grid md:grid-cols-2' : 'flex',
							'flex-wrap',
							'gap-1',
							'mb-6',
							'answers',
							pageIndex < 4 ? 'justify-center' : ''
						)}
					>
						{question.answers.map((answer) => {
							const onClick = () => {
								setCurrentQuestion(question);

								const [foundAnswer, foundIndex] = findAnswer(
									answers,
									question.id
								);
								const newAnswers = getNewAnswers(
									question,
									foundAnswer,
									answer.answer
								);

								// Check to see if there are any sub answers from this question and remove them
								// recursively
								const allSubAnswers = [];
								const subAnswers = findAllSubAnswers(
									answers,
									question.id,
									allSubAnswers
								);

								if (question.type === 'subQuestion') {
									handleSubQuestionAnswersTracking(answer);
								}

								if (
									subAnswers &&
									foundAnswer?.answer !== newAnswers
								) {
									subAnswers.map((subAnswer) =>
										setAnswers((current) => {
											const subIndex = current.findIndex(
												(subA) =>
													String(subA.id) ===
													String(subAnswer.id)
											);

											// Remove the question from the answers if there are no answers selected
											if (subIndex !== -1) {
												current.splice(subIndex, 1);
											}
											return current;
										})
									);
								}

								// Sets that we've answered this question if one or more answers are selected
								setAnswers((current) => {
									if (!foundAnswer) {
										// Add the answer to the answers array if not there already
										return [
											...answers,
											{
												id: String(question.id),
												type:
													question.type ||
													answer.type,
												category: question.category,
												question: question.question,
												answers: [answer.answer],
												resultText: answer.resultText
													? answer.resultText
													: null,
												score: answer.score,
												noComms: answer.noComms || null,
												parentQuestion: originalQuestion
													? String(
															originalQuestion.id
													  )
													: null,
											},
										];
									}

									// Update the answers array if it is there and has changed
									// Check for multiple choice questions and handle appropriately
									if (newAnswers.length > 0) {
										const items = [...current];
										const item = {
											...items[foundIndex],
											type: question.type || answer.type,
											category: question.category,
											answers: newAnswers,
											resultText: answer.resultText
												? answer.resultText
												: null,
											score: answer.score,
											noComms: answer.noComms || null,
											parentQuestion: originalQuestion
												? String(originalQuestion.id)
												: null,
										};
										items[foundIndex] = item;
										return items;
									}

									// Remove the question from the answers if there are no answers selected
									return current.slice(0, foundIndex);
								});

								if (
									answer.subjects &&
									answer.subjects.length > 0
								) {
									setSubjects(answer.subjects);
								}

								if (answer.joinedQuestions) {
									setJoinedQuestions(answer.joinedQuestions);
								}

								if (answer.score >= 1) {
									setUrgent(2);
									// Scroll to the top so you see the top of the urgent pop up
									window.scrollTo({
										top: 0,
										left: 0,
										behavior: 'smooth',
									});
								}
							};
							const [isFound] = findAnswer(answers, question.id);
							const isSelected =
								isFound &&
								isFound.answers.includes(answer.answer);
							return (
								<li
									key={`main-${
										isSubQuestion
											? 'subquestion'
											: 'question'
									}-${question.id}-answer-${answer.id}`}
									className={clsx(
										'flex-initial',
										pageIndex === 1 ? 'w-full' : ''
									)}
								>
									{answer.subSectors ? (
										<BusinessType
											answer={answer}
											onClick={onClick}
											isSelected={isSelected}
											variant={
												isSelected
													? 'BusinessSelected'
													: 'Business'
											}
										/>
									) : (
										<Button
											variant={
												isSelected
													? 'AnswerSelected'
													: 'Answer'
											}
											className="!px-6 answer"
											onClick={onClick}
										>
											{answer.answer}
										</Button>
									)}
								</li>
							);
						})}
					</ul>
				</>
			) : null}
			{question.subQuestions
				? question.subQuestions.map((subQuest) => (
						<Question
							key={`subquestion-${subQuest.id}`}
							question={subQuest}
							pageIndex={pageIndex}
							originalQuestion={question}
							isInvalid={isInvalid}
							setCurrentQuestion={setCurrentQuestion}
							setAnswered={setAnswered}
							answers={answers}
							setAnswers={setAnswers}
							setSubjects={setSubjects}
							setSubCategories={setSubCategories}
							setSubAreas={setSubAreas}
							setSubQuestions={setSubQuestions}
							setJoinedQuestions={setJoinedQuestions}
							setUrgent={setUrgent}
							isSubQuestion
						/>
				  ))
				: null}
		</div>
	);
};

Question.defaultProps = {
	originalQuestion: undefined,
	isSubQuestion: false,
};

Question.propTypes = {
	question: questionType.isRequired,
	pageIndex: PropTypes.number.isRequired,
	isInvalid: PropTypes.shape({
		id: PropTypes.bool,
	}).isRequired,
	setCurrentQuestion: PropTypes.func.isRequired,
	answers: answersType.isRequired,
	setAnswered: PropTypes.func.isRequired,
	setAnswers: PropTypes.func.isRequired,
	setSubjects: PropTypes.func.isRequired,
	setSubCategories: PropTypes.func.isRequired,
	setSubAreas: PropTypes.func.isRequired,
	setSubQuestions: PropTypes.func.isRequired,
	setJoinedQuestions: PropTypes.func.isRequired,
	setUrgent: PropTypes.func.isRequired,
	originalQuestion: questionType,
	isSubQuestion: PropTypes.bool,
};

export { Question };
