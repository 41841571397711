import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { answersType } from '../../../types';

import { NextStepComponent } from '../../molecules/next-steps';
import { AnswersAdvice } from '../../atoms/answers-advice';
import { UrgentAnswersHeader } from '../../atoms/urgent-answers-header';
import { Button } from '../../atoms/button';

import DownArrow from '../../../assets/down-arrow.svg';
import UpArrow from '../../../assets/up-arrow.svg';

const OverlayVariants = {
	Default:
		'h-full fixed overflow-scroll left-0 top-0 w-full motion-reduce:transition duration-300 z-10 bg-brand-blue-400 pt-6',
	Hidden: 'opacity-0 translate-y-full pointer-events-none',
	Showing: 'opacity-1 translate-y-0 pointer-events-all',
};

const UrgentPage = ({ isUrgent, setUrgent, answers, pageIndex }) => {
	useEffect(() => {
		const hasWindow = typeof window !== 'undefined';
		const hasGtag = typeof window.gtag === 'function';

		if (hasWindow && hasGtag && isUrgent === 2) {
			window.gtag('event', 'page_view', {
				page_location: '/advice/red-flag',
				page_title: 'Business Health Assistant - Red Flag',
			});
		}
	}, [isUrgent]);

	return (
		<>
			{isUrgent === 1 && pageIndex > 4 ? (
				<section
					className="fixed bottom-0 left-0 w-full p-4 text-center bg-brand-blue-400"
					style={{ '--space-vertical': '0' }}
				>
					<Button
						variant="Ghost"
						className="!px-4 !py-2"
						onClick={() => {
							setUrgent(2);
						}}
					>
						<UpArrow width={14} height={20} className="mr-2" />{' '}
						Return to results
					</Button>
				</section>
			) : null}
			<div
				className={clsx(
					OverlayVariants.Default,
					OverlayVariants[isUrgent === 2 ? 'Showing' : 'Hidden']
				)}
				style={{ '--space-vertical': '0' }}
			>
				<div className="px-4">
					<div className="mx-auto wrapper-small">
						<p className="pb-6 text-center border-b border-white border-solid">
							<Button
								variant="Ghost"
								className="!px-4 !py-2"
								onClick={() => {
									setUrgent(1);
								}}
							>
								<DownArrow
									width={20}
									height={20}
									className="mr-2"
								/>{' '}
								edit answers
							</Button>
						</p>
						<section className="text-white bg-brand-blue-400">
							<UrgentAnswersHeader answers={answers} />
							<NextStepComponent answers={answers} urgent />
							<p className="flex justify-center items-center text-center text-sm my-[30px] font-centra-medium">
								<DownArrow
									width={20}
									height={20}
									className="mr-2"
								/>
								Alternatively, view your results
							</p>
						</section>
					</div>
					<AnswersAdvice answers={answers} />
				</div>
			</div>
		</>
	);
};

UrgentPage.propTypes = {
	isUrgent: PropTypes.number.isRequired,
	setUrgent: PropTypes.func.isRequired,
	answers: answersType.isRequired,
	pageIndex: PropTypes.number.isRequired,
};

export { UrgentPage };
