import React, { useState } from 'react';
import { clsx } from 'clsx';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import data from '../data/data.json';
// If you want to test an answer use this for the answer state below
// import { answerData } from '../data/answers';

import { Head } from '../components/templates/head';

import { Questions } from '../components/organisms/questions';
import { UrgentPage } from '../components/organisms/urgentpage';
import { Header } from '../components/molecules/header';
import { Button } from '../components/atoms/button';
import { CloseButton } from '../components/atoms/close-button';

import RestartIcon from '../assets/restart.svg';
import LeftArrowIcon from '../assets/left-arrow.svg';

const { page } = data;

// markup
const IndexPage = () => {
	// TODO - make sure the data is fresh!
	// Seems to remember things when coming here from an internal link but should be fresh
	const [pageIndex, setPageIndex] = useState(page);
	// 0 = not urgent
	// 1 = urgent but overlay is hidden
	// 2 = urgent and overlay is visible
	const [isUrgent, setUrgent] = useState(0);
	const [answers, setAnswers] = useState([]);
	const [pageData, setPageData] = useState(data.pages[pageIndex]);
	const [isComplete, setIsComplete] = useState(false);

	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);

	// Switch to render the right component based on where we are in the process
	const renderSwitch = () => {
		if (isComplete) {
			navigate('/answers', { state: { answers } });
		}
		return (
			<>
				<Questions
					allData={data}
					pageData={pageData}
					answers={answers}
					setAnswers={setAnswers}
					setPageData={setPageData}
					pageIndex={pageIndex}
					setPageIndex={setPageIndex}
					isUrgent={isUrgent}
					setUrgent={setUrgent}
					setIsComplete={setIsComplete}
				/>
				<UrgentPage
					isUrgent={isUrgent}
					setUrgent={setUrgent}
					answers={answers}
					pageIndex={pageIndex}
				/>
			</>
		);
	};

	return (
		<>
			<Head title={site.siteMetadata.title} />
			<main
				id="main"
				className={clsx(
					'container',
					'px-4',
					'py-7',
					'md:py-0',
					'sm:mx-auto',
					isUrgent === 2 ? '' : 'relative overflow-hidden'
				)}
			>
				<div
					className={clsx(
						'md:pt-7',
						pageIndex < 4 ? 'text-center' : ''
					)}
				>
					<div
						className={clsx(
							'w-full max-w-full min-w-0 flow-vertical',
							isUrgent ? 'pb-24' : 'pb-10'
						)}
						style={{ '--space-vertical': 'var(--space-l-xl)' }}
					>
						{pageIndex === 0 ? (
							<Header
								backBtn={
									<Button
										variant="White"
										onClick={() => {
											navigate('/');
										}}
										className="flex items-center !py-xxs-f !px-l-f"
									>
										<LeftArrowIcon className="mr-2 max-w-none" />
										Back
									</Button>
								}
								restartBtn={
									pageIndex > 0 ? (
										<CloseButton
											className="!py-xxs-f !px-l-f"
											destination="/"
											promptTitle="Restart Triage"
											promptDesc="Are you sure you want to restart? You will lose all information added so far."
											id="questionsRestart"
										>
											<RestartIcon className="mr-2" />
											Restart
										</CloseButton>
									) : null
								}
							/>
						) : null}
						{renderSwitch()}
					</div>
				</div>
			</main>
		</>
	);
};

export default IndexPage;
